:root {
  --visme-blue: #2693FF;
  --visme-indigo: #6610f2;
  --visme-purple: #9062F0;
  --visme-pink: #d63384;
  --visme-red: #F45F50;
  --visme-orange: #FCBC41;
  --visme-yellow: #F2E150;
  --visme-green: #6CC395;
  --visme-teal: #20c997;
  --visme-cyan: #0dcaf0;
  --visme-black: #000;
  --visme-white: #fff;
  --visme-gray: #6c757d;
  --visme-gray-dark: #343a40;
  --visme-gray-100: #f8f9fa;
  --visme-gray-200: #e9ecef;
  --visme-gray-300: #dee2e6;
  --visme-gray-400: #ced4da;
  --visme-gray-500: #adb5bd;
  --visme-gray-600: #6c757d;
  --visme-gray-700: #495057;
  --visme-gray-800: #343a40;
  --visme-gray-900: #212529;
  --visme-primary: #3CACD7;
  --visme-secondary: #E7EFF3;
  --visme-success: #6CC395;
  --visme-info: #0dcaf0;
  --visme-warning: #F2E150;
  --visme-danger: #F45F50;
  --visme-light: #f8f9fa;
  --visme-dark: #212529;
  --visme-body: #293745;
  --visme-sky-bridge: #3CACD7;
  --visme-sky-clean: #50C1EC;
  --visme-black-panther: #293745;
  --visme-the-dcotor: #F8FAFB;
  --visme-dark-1: #293745;
  --visme-dark-2: #2B3140;
  --visme-dark-3: #3F4759;
  --visme-dark-4: #586276;
  --visme-dark-5: #798297;
  --visme-dark-6: #949DB0;
  --visme-base-1: #7F859D;
  --visme-base-2: #A0A5B8;
  --visme-base-3: #BBD0E5;
  --visme-base-4: #DAE4EA;
  --visme-base-5: #E7EFF3;
  --visme-base-6: #EFF6FC;
  --visme-mid-1: #97989A;
  --visme-mid-2: #ABABAB;
  --visme-mid-3: #DADADA;
  --visme-mid-4: #E4E4E4;
  --visme-mid-5: #F3F3F3;
  --visme-mid-6: #FDFDFD;
  --visme-warning-1: #CF9627;
  --visme-warning-2: #E4A833;
  --visme-warning-4: #FECF73;
  --visme-warning-5: #FFE5B1;
  --visme-success-1: #48906B;
  --visme-success-2: #5EAE84;
  --visme-success-4: #98DCB8;
  --visme-success-5: #CAF5DF;
  --visme-danger-1: #CA4438;
  --visme-danger-2: #E15144;
  --visme-danger-4: #FF7669;
  --visme-danger-5: #FFAEA7;
  --visme-primary-rgb: 60, 172, 215;
  --visme-secondary-rgb: 231, 239, 243;
  --visme-success-rgb: 108, 195, 149;
  --visme-info-rgb: 13, 202, 240;
  --visme-warning-rgb: 242, 225, 80;
  --visme-danger-rgb: 244, 95, 80;
  --visme-light-rgb: 248, 249, 250;
  --visme-dark-rgb: 33, 37, 41;
  --visme-white-rgb: 255, 255, 255;
  --visme-black-rgb: 0, 0, 0;
  --visme-body-color-rgb: 41, 55, 69;
  --visme-body-bg-rgb: 255, 255, 255;
  --visme-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --visme-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --visme-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --visme-body-font-family: Lato, sans-serif;
  --visme-body-font-size: 1rem;
  --visme-body-font-weight: 400;
  --visme-body-line-height: 1.5;
  --visme-body-color: #293745;
  --visme-body-bg: #fff;
  --visme-border-width: 1px;
  --visme-border-style: solid;
  --visme-border-color: #dee2e6;
  --visme-border-color-translucent: rgba(0, 0, 0, 0.175);
  --visme-border-radius: 6px;
  --visme-border-radius-sm: 0.25rem;
  --visme-border-radius-lg: 0.5rem;
  --visme-border-radius-xl: 1rem;
  --visme-border-radius-2xl: 2rem;
  --visme-border-radius-pill: 50rem;
  --visme-heading-color: ;
  --visme-link-color: #3CACD7;
  --visme-link-hover-color: #50C1EC;
  --visme-code-color: #d63384;
  --visme-highlight-bg: #fcf9dc;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--visme-body-font-family);
  font-size: var(--visme-body-font-size);
  font-weight: var(--visme-body-font-weight);
  line-height: var(--visme-body-line-height);
  color: var(--visme-body-color);
  text-align: var(--visme-body-text-align);
  background-color: var(--visme-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  line-height: 1.3;
  color: var(--visme-heading-color);
}

h1 {
  font-size: calc(1.425rem + 2.1vw);
  line-height: 1.15625;
}
@media (min-width: 1200px) {
  h1 {
    font-size: 3rem;
  }
}

@media (min-width: 1400px) {
  h1 {
    font-size: 4rem;
  }
}
h2 {
  font-size: calc(1.4rem + 1.8vw);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 2.75rem;
  }
}

@media (min-width: 1400px) {
  h2 {
    font-size: 3rem;
    line-height: 1.2727;
  }
}
h3 {
  font-size: calc(1.3375rem + 1.05vw);
}
@media (min-width: 1200px) {
  h3 {
    font-size: 2.125rem;
  }
}

@media (min-width: 1400px) {
  h3 {
    font-size: 2.75rem;
    line-height: 1.2727;
  }
}
h4 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h4 {
    font-size: 1.75rem;
  }
}

h5 {
  font-size: calc(1.275rem + 0.3vw);
  font-family: Lato, sans-serif;
}
@media (min-width: 1200px) {
  h5 {
    font-size: 1.5rem;
  }
}

h6 {
  font-size: 1.25rem;
  font-family: Lato, sans-serif;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark {
  padding: 0.1875em;
  background-color: var(--visme-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--visme-link-color);
  text-decoration: none;
}
a:hover {
  color: var(--visme-link-hover-color);
  text-decoration: none;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--visme-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--visme-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--visme-body-bg);
  background-color: var(--visme-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: rgba(var(--visme-body-color-rgb), 0.75);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.container,
.container-fluid,
.container-xl,
.container-lg {
  --visme-gutter-x: 24px;
  --visme-gutter-y: 0;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1024px) {
  .container-lg, .container-sm, .container {
    max-width: 1280px;
  }
}
@media (min-width: 1600px) {
  .container-xl, .container-lg, .container-sm, .container {
    max-width: 1440px;
  }
}
.row {
  --visme-gutter-x: 24px;
  --visme-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--visme-gutter-y));
  margin-right: calc(-0.5 * var(--visme-gutter-x));
  margin-left: calc(-0.5 * var(--visme-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--visme-gutter-x) * 0.5);
  padding-left: calc(var(--visme-gutter-x) * 0.5);
  margin-top: var(--visme-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --visme-gutter-x: 0;
}

.g-0,
.gy-0 {
  --visme-gutter-y: 0;
}

.g-1,
.gx-1 {
  --visme-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --visme-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --visme-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --visme-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --visme-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --visme-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --visme-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --visme-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --visme-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --visme-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --visme-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --visme-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --visme-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --visme-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --visme-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --visme-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --visme-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --visme-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --visme-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --visme-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --visme-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --visme-gutter-y: 3rem;
  }
}
@media (min-width: 1024px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --visme-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --visme-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --visme-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --visme-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --visme-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --visme-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --visme-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --visme-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --visme-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --visme-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --visme-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --visme-gutter-y: 3rem;
  }
}
@media (min-width: 1600px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --visme-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --visme-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --visme-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --visme-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --visme-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --visme-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --visme-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --visme-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --visme-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --visme-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --visme-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --visme-gutter-y: 3rem;
  }
}
.btn {
  --visme-btn-padding-x: 36px;
  --visme-btn-padding-y: 18px;
  --visme-btn-font-family: ;
  --visme-btn-font-size: 1.25rem;
  --visme-btn-font-weight: 700;
  --visme-btn-line-height: 1.2;
  --visme-btn-color: #293745;
  --visme-btn-bg: transparent;
  --visme-btn-border-width: 1px;
  --visme-btn-border-color: transparent;
  --visme-btn-border-radius: 6px;
  --visme-btn-box-shadow: none;
  --visme-btn-disabled-opacity: 0.65;
  --visme-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--visme-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--visme-btn-padding-y) var(--visme-btn-padding-x);
  font-family: var(--visme-btn-font-family);
  font-size: var(--visme-btn-font-size);
  font-weight: var(--visme-btn-font-weight);
  line-height: var(--visme-btn-line-height);
  color: var(--visme-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--visme-btn-border-width) solid var(--visme-btn-border-color);
  border-radius: var(--visme-btn-border-radius);
  background-color: var(--visme-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--visme-btn-hover-color);
  background-color: var(--visme-btn-hover-bg);
  border-color: var(--visme-btn-hover-border-color);
}
.btn-check:focus + .btn, .btn:focus {
  color: var(--visme-btn-hover-color);
  background-color: var(--visme-btn-hover-bg);
  border-color: var(--visme-btn-hover-border-color);
  outline: 0;
}
.btn-check:checked + .btn, .btn-check:active + .btn, .btn:active, .btn.active, .btn.show {
  color: var(--visme-btn-active-color);
  background-color: var(--visme-btn-active-bg);
  border-color: var(--visme-btn-active-border-color);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--visme-btn-disabled-color);
  pointer-events: none;
  background-color: var(--visme-btn-disabled-bg);
  border-color: var(--visme-btn-disabled-border-color);
  opacity: var(--visme-btn-disabled-opacity);
}

.btn-primary {
  --visme-btn-color: #000;
  --visme-btn-bg: #3CACD7;
  --visme-btn-border-color: #3CACD7;
  --visme-btn-hover-color: #000;
  --visme-btn-hover-bg: #59b8dd;
  --visme-btn-hover-border-color: #50b4db;
  --visme-btn-focus-shadow-rgb: 51, 146, 183;
  --visme-btn-active-color: #000;
  --visme-btn-active-bg: #63bddf;
  --visme-btn-active-border-color: #50b4db;
  --visme-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --visme-btn-disabled-color: #000;
  --visme-btn-disabled-bg: #3CACD7;
  --visme-btn-disabled-border-color: #3CACD7;
}

.btn-secondary {
  --visme-btn-color: #000;
  --visme-btn-bg: #E7EFF3;
  --visme-btn-border-color: #E7EFF3;
  --visme-btn-hover-color: #000;
  --visme-btn-hover-bg: #ebf1f5;
  --visme-btn-hover-border-color: #e9f1f4;
  --visme-btn-focus-shadow-rgb: 196, 203, 207;
  --visme-btn-active-color: #000;
  --visme-btn-active-bg: #ecf2f5;
  --visme-btn-active-border-color: #e9f1f4;
  --visme-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --visme-btn-disabled-color: #000;
  --visme-btn-disabled-bg: #E7EFF3;
  --visme-btn-disabled-border-color: #E7EFF3;
}

.btn-success {
  --visme-btn-color: #000;
  --visme-btn-bg: #6CC395;
  --visme-btn-border-color: #6CC395;
  --visme-btn-hover-color: #000;
  --visme-btn-hover-bg: #82cca5;
  --visme-btn-hover-border-color: #7bc9a0;
  --visme-btn-focus-shadow-rgb: 92, 166, 127;
  --visme-btn-active-color: #000;
  --visme-btn-active-bg: #89cfaa;
  --visme-btn-active-border-color: #7bc9a0;
  --visme-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --visme-btn-disabled-color: #000;
  --visme-btn-disabled-bg: #6CC395;
  --visme-btn-disabled-border-color: #6CC395;
}

.btn-info {
  --visme-btn-color: #000;
  --visme-btn-bg: #0dcaf0;
  --visme-btn-border-color: #0dcaf0;
  --visme-btn-hover-color: #000;
  --visme-btn-hover-bg: #31d2f2;
  --visme-btn-hover-border-color: #25cff2;
  --visme-btn-focus-shadow-rgb: 11, 172, 204;
  --visme-btn-active-color: #000;
  --visme-btn-active-bg: #3dd5f3;
  --visme-btn-active-border-color: #25cff2;
  --visme-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --visme-btn-disabled-color: #000;
  --visme-btn-disabled-bg: #0dcaf0;
  --visme-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --visme-btn-color: #000;
  --visme-btn-bg: #F2E150;
  --visme-btn-border-color: #F2E150;
  --visme-btn-hover-color: #000;
  --visme-btn-hover-bg: #f4e66a;
  --visme-btn-hover-border-color: #f3e462;
  --visme-btn-focus-shadow-rgb: 206, 191, 68;
  --visme-btn-active-color: #000;
  --visme-btn-active-bg: #f5e773;
  --visme-btn-active-border-color: #f3e462;
  --visme-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --visme-btn-disabled-color: #000;
  --visme-btn-disabled-bg: #F2E150;
  --visme-btn-disabled-border-color: #F2E150;
}

.btn-danger {
  --visme-btn-color: #000;
  --visme-btn-bg: #F45F50;
  --visme-btn-border-color: #F45F50;
  --visme-btn-hover-color: #000;
  --visme-btn-hover-bg: #f6776a;
  --visme-btn-hover-border-color: #f56f62;
  --visme-btn-focus-shadow-rgb: 207, 81, 68;
  --visme-btn-active-color: #000;
  --visme-btn-active-bg: #f67f73;
  --visme-btn-active-border-color: #f56f62;
  --visme-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --visme-btn-disabled-color: #000;
  --visme-btn-disabled-bg: #F45F50;
  --visme-btn-disabled-border-color: #F45F50;
}

.btn-light {
  --visme-btn-color: #000;
  --visme-btn-bg: #f8f9fa;
  --visme-btn-border-color: #f8f9fa;
  --visme-btn-hover-color: #000;
  --visme-btn-hover-bg: #f9fafb;
  --visme-btn-hover-border-color: #f9fafb;
  --visme-btn-focus-shadow-rgb: 211, 212, 213;
  --visme-btn-active-color: #000;
  --visme-btn-active-bg: #f9fafb;
  --visme-btn-active-border-color: #f9fafb;
  --visme-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --visme-btn-disabled-color: #000;
  --visme-btn-disabled-bg: #f8f9fa;
  --visme-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --visme-btn-color: #fff;
  --visme-btn-bg: #212529;
  --visme-btn-border-color: #212529;
  --visme-btn-hover-color: #fff;
  --visme-btn-hover-bg: #1c1f23;
  --visme-btn-hover-border-color: #1a1e21;
  --visme-btn-focus-shadow-rgb: 66, 70, 73;
  --visme-btn-active-color: #fff;
  --visme-btn-active-bg: #1a1e21;
  --visme-btn-active-border-color: #191c1f;
  --visme-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --visme-btn-disabled-color: #fff;
  --visme-btn-disabled-bg: #212529;
  --visme-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --visme-btn-color: #3CACD7;
  --visme-btn-border-color: #3CACD7;
  --visme-btn-hover-color: #000;
  --visme-btn-hover-bg: #3CACD7;
  --visme-btn-hover-border-color: #3CACD7;
  --visme-btn-focus-shadow-rgb: 60, 172, 215;
  --visme-btn-active-color: #000;
  --visme-btn-active-bg: #3CACD7;
  --visme-btn-active-border-color: #3CACD7;
  --visme-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --visme-btn-disabled-color: #3CACD7;
  --visme-btn-disabled-bg: transparent;
  --visme-gradient: none;
}

.btn-outline-secondary {
  --visme-btn-color: #E7EFF3;
  --visme-btn-border-color: #E7EFF3;
  --visme-btn-hover-color: #000;
  --visme-btn-hover-bg: #E7EFF3;
  --visme-btn-hover-border-color: #E7EFF3;
  --visme-btn-focus-shadow-rgb: 231, 239, 243;
  --visme-btn-active-color: #000;
  --visme-btn-active-bg: #E7EFF3;
  --visme-btn-active-border-color: #E7EFF3;
  --visme-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --visme-btn-disabled-color: #E7EFF3;
  --visme-btn-disabled-bg: transparent;
  --visme-gradient: none;
}

.btn-outline-success {
  --visme-btn-color: #6CC395;
  --visme-btn-border-color: #6CC395;
  --visme-btn-hover-color: #000;
  --visme-btn-hover-bg: #6CC395;
  --visme-btn-hover-border-color: #6CC395;
  --visme-btn-focus-shadow-rgb: 108, 195, 149;
  --visme-btn-active-color: #000;
  --visme-btn-active-bg: #6CC395;
  --visme-btn-active-border-color: #6CC395;
  --visme-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --visme-btn-disabled-color: #6CC395;
  --visme-btn-disabled-bg: transparent;
  --visme-gradient: none;
}

.btn-outline-info {
  --visme-btn-color: #0dcaf0;
  --visme-btn-border-color: #0dcaf0;
  --visme-btn-hover-color: #000;
  --visme-btn-hover-bg: #0dcaf0;
  --visme-btn-hover-border-color: #0dcaf0;
  --visme-btn-focus-shadow-rgb: 13, 202, 240;
  --visme-btn-active-color: #000;
  --visme-btn-active-bg: #0dcaf0;
  --visme-btn-active-border-color: #0dcaf0;
  --visme-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --visme-btn-disabled-color: #0dcaf0;
  --visme-btn-disabled-bg: transparent;
  --visme-gradient: none;
}

.btn-outline-warning {
  --visme-btn-color: #F2E150;
  --visme-btn-border-color: #F2E150;
  --visme-btn-hover-color: #000;
  --visme-btn-hover-bg: #F2E150;
  --visme-btn-hover-border-color: #F2E150;
  --visme-btn-focus-shadow-rgb: 242, 225, 80;
  --visme-btn-active-color: #000;
  --visme-btn-active-bg: #F2E150;
  --visme-btn-active-border-color: #F2E150;
  --visme-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --visme-btn-disabled-color: #F2E150;
  --visme-btn-disabled-bg: transparent;
  --visme-gradient: none;
}

.btn-outline-danger {
  --visme-btn-color: #F45F50;
  --visme-btn-border-color: #F45F50;
  --visme-btn-hover-color: #000;
  --visme-btn-hover-bg: #F45F50;
  --visme-btn-hover-border-color: #F45F50;
  --visme-btn-focus-shadow-rgb: 244, 95, 80;
  --visme-btn-active-color: #000;
  --visme-btn-active-bg: #F45F50;
  --visme-btn-active-border-color: #F45F50;
  --visme-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --visme-btn-disabled-color: #F45F50;
  --visme-btn-disabled-bg: transparent;
  --visme-gradient: none;
}

.btn-outline-light {
  --visme-btn-color: #f8f9fa;
  --visme-btn-border-color: #f8f9fa;
  --visme-btn-hover-color: #000;
  --visme-btn-hover-bg: #f8f9fa;
  --visme-btn-hover-border-color: #f8f9fa;
  --visme-btn-focus-shadow-rgb: 248, 249, 250;
  --visme-btn-active-color: #000;
  --visme-btn-active-bg: #f8f9fa;
  --visme-btn-active-border-color: #f8f9fa;
  --visme-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --visme-btn-disabled-color: #f8f9fa;
  --visme-btn-disabled-bg: transparent;
  --visme-gradient: none;
}

.btn-outline-dark {
  --visme-btn-color: #212529;
  --visme-btn-border-color: #212529;
  --visme-btn-hover-color: #fff;
  --visme-btn-hover-bg: #212529;
  --visme-btn-hover-border-color: #212529;
  --visme-btn-focus-shadow-rgb: 33, 37, 41;
  --visme-btn-active-color: #fff;
  --visme-btn-active-bg: #212529;
  --visme-btn-active-border-color: #212529;
  --visme-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --visme-btn-disabled-color: #212529;
  --visme-btn-disabled-bg: transparent;
  --visme-gradient: none;
}

.btn-link {
  --visme-btn-font-weight: 400;
  --visme-btn-color: var(--visme-link-color);
  --visme-btn-bg: transparent;
  --visme-btn-border-color: transparent;
  --visme-btn-hover-color: var(--visme-link-hover-color);
  --visme-btn-hover-border-color: transparent;
  --visme-btn-active-border-color: transparent;
  --visme-btn-disabled-color: #6c757d;
  --visme-btn-disabled-border-color: transparent;
  --visme-btn-box-shadow: none;
  text-decoration: none;
}
.btn-link:hover, .btn-link:focus {
  text-decoration: none;
}

.btn-lg {
  --visme-btn-padding-y: 0.5rem;
  --visme-btn-padding-x: 1rem;
  --visme-btn-font-size: 1.25rem;
  --visme-btn-border-radius: 0.5rem;
}

.btn-sm {
  --visme-btn-padding-y: 0.25rem;
  --visme-btn-padding-x: 0.5rem;
  --visme-btn-font-size: 0.875rem;
  --visme-btn-border-radius: 0.25rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.accordion {
  --visme-accordion-color: #000;
  --visme-accordion-bg: #F8FAFB;
  --visme-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --visme-accordion-border-color: #ffffff;
  --visme-accordion-border-width: 0;
  --visme-accordion-border-radius: 1rem;
  --visme-accordion-inner-border-radius: 1rem;
  --visme-accordion-btn-padding-x: 1.25rem;
  --visme-accordion-btn-padding-y: 1rem;
  --visme-accordion-btn-color: #293745;
  --visme-accordion-btn-bg: #ffffff;
  --visme-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 14' fill='%23293745'%3e%3cpath fill-rule='evenodd' d='M0.292893 0.292893C0.683418 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7.70711 6.29289C8.09763 6.68342 8.09763 7.31658 7.70711 7.70711L1.70711 13.7071C1.31658 14.0976 0.683418 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L5.58579 7L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893Z'/%3e%3c/svg%3e");
  --visme-accordion-btn-icon-width: 1.5rem;
  --visme-accordion-btn-icon-transform: rotate(90deg);
  --visme-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --visme-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 14' fill='%23949DB0'%3e%3cpath fill-rule='evenodd' d='M0.292893 0.292893C0.683418 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7.70711 6.29289C8.09763 6.68342 8.09763 7.31658 7.70711 7.70711L1.70711 13.7071C1.31658 14.0976 0.683418 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L5.58579 7L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893Z'/%3e%3c/svg%3e");
  --visme-accordion-btn-focus-border-color: #ffffff;
  --visme-accordion-btn-focus-box-shadow: none;
  --visme-accordion-body-padding-x: 1.25rem;
  --visme-accordion-body-padding-y: 1rem;
  --visme-accordion-active-color: #293745;
  --visme-accordion-active-bg: #F8FAFB;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--visme-accordion-btn-padding-y) var(--visme-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--visme-accordion-btn-color);
  text-align: left;
  background-color: var(--visme-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--visme-accordion-transition);
  border-top-right-radius: var(--visme-accordion-border-radius);
  border-bottom-right-radius: var(--visme-accordion-border-radius);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--visme-accordion-active-color);
  background-color: var(--visme-accordion-active-bg);
  box-shadow: inset 0 calc(var(--visme-accordion-border-width) * -1) 0 var(--visme-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--visme-accordion-btn-active-icon);
  transform: var(--visme-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--visme-accordion-btn-icon-width);
  height: var(--visme-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--visme-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: 8px 14px;
  background-position: 50% 50%;
  transition: var(--visme-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--visme-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--visme-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--visme-accordion-color);
  background-color: var(--visme-accordion-bg);
  border: var(--visme-accordion-border-width) solid var(--visme-accordion-border-color);
  border-top-right-radius: var(--visme-accordion-border-radius);
  border-bottom-right-radius: var(--visme-accordion-border-radius);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--visme-accordion-border-radius);
  border-top-right-radius: var(--visme-accordion-border-radius);
  border-top-left-radius: 0;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--visme-accordion-inner-border-radius);
  border-top-right-radius: var(--visme-accordion-inner-border-radius);
  border-top-left-radius: 0;
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--visme-accordion-border-radius);
  border-bottom-left-radius: var(--visme-accordion-border-radius);
  border-bottom-left-radius: 0;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--visme-accordion-inner-border-radius);
  border-bottom-left-radius: var(--visme-accordion-inner-border-radius);
  border-bottom-left-radius: 0;
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--visme-accordion-border-radius);
  border-bottom-left-radius: var(--visme-accordion-border-radius);
  border-bottom-left-radius: 0;
}

.accordion-body {
  padding: var(--visme-accordion-body-padding-y) var(--visme-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

/*
@import "dropdown";
@import "button-group";
@import "nav";
@import "navbar";
@import "card";
@import "accordion";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "alert";
@import "progress";
@import "list-group";
*/
/*
@import "tooltip";
@import "popover";
@import "carousel";
@import "spinners";
@import "offcanvas";
@import "placeholders";
*/
.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--visme-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 1023.98px) {
  .ratio-lg-hidden {
    position: relative;
    width: 100%;
  }
  .ratio-lg-hidden::before {
    display: block;
    padding-top: var(--visme-aspect-ratio);
    content: "";
  }
  .ratio-lg-hidden > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.ratio-1x1 {
  --visme-aspect-ratio: 100%;
}

.ratio-4x3 {
  --visme-aspect-ratio: 75%;
}

.ratio-16x9 {
  --visme-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --visme-aspect-ratio: 42.8571428571%;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--visme-border-width) var(--visme-border-style) var(--visme-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--visme-border-width) var(--visme-border-style) var(--visme-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--visme-border-width) var(--visme-border-style) var(--visme-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--visme-border-width) var(--visme-border-style) var(--visme-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--visme-border-width) var(--visme-border-style) var(--visme-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --visme-border-opacity: 1;
  border-color: rgba(var(--visme-primary-rgb), var(--visme-border-opacity)) !important;
}

.border-secondary {
  --visme-border-opacity: 1;
  border-color: rgba(var(--visme-secondary-rgb), var(--visme-border-opacity)) !important;
}

.border-success {
  --visme-border-opacity: 1;
  border-color: rgba(var(--visme-success-rgb), var(--visme-border-opacity)) !important;
}

.border-info {
  --visme-border-opacity: 1;
  border-color: rgba(var(--visme-info-rgb), var(--visme-border-opacity)) !important;
}

.border-warning {
  --visme-border-opacity: 1;
  border-color: rgba(var(--visme-warning-rgb), var(--visme-border-opacity)) !important;
}

.border-danger {
  --visme-border-opacity: 1;
  border-color: rgba(var(--visme-danger-rgb), var(--visme-border-opacity)) !important;
}

.border-light {
  --visme-border-opacity: 1;
  border-color: rgba(var(--visme-light-rgb), var(--visme-border-opacity)) !important;
}

.border-dark {
  --visme-border-opacity: 1;
  border-color: rgba(var(--visme-dark-rgb), var(--visme-border-opacity)) !important;
}

.border-white {
  --visme-border-opacity: 1;
  border-color: rgba(var(--visme-white-rgb), var(--visme-border-opacity)) !important;
}

.border-1 {
  --visme-border-width: 1px;
}

.border-2 {
  --visme-border-width: 2px;
}

.border-3 {
  --visme-border-width: 3px;
}

.border-4 {
  --visme-border-width: 4px;
}

.border-5 {
  --visme-border-width: 5px;
}

.border-opacity-10 {
  --visme-border-opacity: 0.1;
}

.border-opacity-25 {
  --visme-border-opacity: 0.25;
}

.border-opacity-50 {
  --visme-border-opacity: 0.5;
}

.border-opacity-75 {
  --visme-border-opacity: 0.75;
}

.border-opacity-100 {
  --visme-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--visme-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.425rem + 2.1vw) !important;
}

.fs-2 {
  font-size: calc(1.4rem + 1.8vw) !important;
}

.fs-3 {
  font-size: calc(1.3375rem + 1.05vw) !important;
}

.fs-4 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-5 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-6 {
  font-size: 1.25rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --visme-text-opacity: 1;
  color: rgba(var(--visme-primary-rgb), var(--visme-text-opacity)) !important;
}

.text-secondary {
  --visme-text-opacity: 1;
  color: rgba(var(--visme-secondary-rgb), var(--visme-text-opacity)) !important;
}

.text-success {
  --visme-text-opacity: 1;
  color: rgba(var(--visme-success-rgb), var(--visme-text-opacity)) !important;
}

.text-info {
  --visme-text-opacity: 1;
  color: rgba(var(--visme-info-rgb), var(--visme-text-opacity)) !important;
}

.text-warning {
  --visme-text-opacity: 1;
  color: rgba(var(--visme-warning-rgb), var(--visme-text-opacity)) !important;
}

.text-danger {
  --visme-text-opacity: 1;
  color: rgba(var(--visme-danger-rgb), var(--visme-text-opacity)) !important;
}

.text-light {
  --visme-text-opacity: 1;
  color: rgba(var(--visme-light-rgb), var(--visme-text-opacity)) !important;
}

.text-dark {
  --visme-text-opacity: 1;
  color: rgba(var(--visme-dark-rgb), var(--visme-text-opacity)) !important;
}

.text-black {
  --visme-text-opacity: 1;
  color: rgba(var(--visme-black-rgb), var(--visme-text-opacity)) !important;
}

.text-white {
  --visme-text-opacity: 1;
  color: rgba(var(--visme-white-rgb), var(--visme-text-opacity)) !important;
}

.text-body {
  --visme-text-opacity: 1;
  color: rgba(var(--visme-body-color-rgb), var(--visme-text-opacity)) !important;
}

.text-muted {
  --visme-text-opacity: 1;
  color: rgba(var(--visme-body-color-rgb), 0.75) !important;
}

.text-black-50 {
  --visme-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --visme-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --visme-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --visme-text-opacity: 0.25;
}

.text-opacity-50 {
  --visme-text-opacity: 0.5;
}

.text-opacity-75 {
  --visme-text-opacity: 0.75;
}

.text-opacity-100 {
  --visme-text-opacity: 1;
}

.bg-primary {
  --visme-bg-opacity: 1;
  background-color: rgba(var(--visme-primary-rgb), var(--visme-bg-opacity)) !important;
}

.bg-secondary {
  --visme-bg-opacity: 1;
  background-color: rgba(var(--visme-secondary-rgb), var(--visme-bg-opacity)) !important;
}

.bg-success {
  --visme-bg-opacity: 1;
  background-color: rgba(var(--visme-success-rgb), var(--visme-bg-opacity)) !important;
}

.bg-info {
  --visme-bg-opacity: 1;
  background-color: rgba(var(--visme-info-rgb), var(--visme-bg-opacity)) !important;
}

.bg-warning {
  --visme-bg-opacity: 1;
  background-color: rgba(var(--visme-warning-rgb), var(--visme-bg-opacity)) !important;
}

.bg-danger {
  --visme-bg-opacity: 1;
  background-color: rgba(var(--visme-danger-rgb), var(--visme-bg-opacity)) !important;
}

.bg-light {
  --visme-bg-opacity: 1;
  background-color: rgba(var(--visme-light-rgb), var(--visme-bg-opacity)) !important;
}

.bg-dark {
  --visme-bg-opacity: 1;
  background-color: rgba(var(--visme-dark-rgb), var(--visme-bg-opacity)) !important;
}

.bg-black {
  --visme-bg-opacity: 1;
  background-color: rgba(var(--visme-black-rgb), var(--visme-bg-opacity)) !important;
}

.bg-white {
  --visme-bg-opacity: 1;
  background-color: rgba(var(--visme-white-rgb), var(--visme-bg-opacity)) !important;
}

.bg-body {
  --visme-bg-opacity: 1;
  background-color: rgba(var(--visme-body-bg-rgb), var(--visme-bg-opacity)) !important;
}

.bg-transparent {
  --visme-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --visme-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --visme-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --visme-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --visme-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --visme-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--visme-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--visme-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--visme-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--visme-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--visme-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--visme-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--visme-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--visme-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--visme-border-radius) !important;
  border-top-right-radius: var(--visme-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--visme-border-radius) !important;
  border-bottom-right-radius: var(--visme-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--visme-border-radius) !important;
  border-bottom-left-radius: var(--visme-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--visme-border-radius) !important;
  border-top-left-radius: var(--visme-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 1024px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1600px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 3rem !important;
  }

  .fs-2 {
    font-size: 2.75rem !important;
  }

  .fs-3 {
    font-size: 2.125rem !important;
  }

  .fs-4 {
    font-size: 1.75rem !important;
  }

  .fs-5 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
.lead {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
}

.large {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
}

@media (min-width: 1200px) {
  .lead {
    font-size: 1.25rem;
  }

  .large {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.45;
  }
}
.list-unstyled {
  list-style: none;
}

.user-select-none {
  -moz-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}

.box-shadow {
  box-shadow: 0px 56px 80px rgba(0, 0, 0, 0.04);
}

.br-6 {
  border-radius: 0.375rem;
}

.br-8 {
  border-radius: 0.5rem;
}

.br-12 {
  border-radius: 0.75rem;
}

.br-16 {
  border-radius: 1rem;
}

.br-24 {
  border-radius: 1.5rem;
}

.br-50 {
  border-radius: 50%;
}

.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.has-gradient {
  background: linear-gradient(90.09deg, #1A2A6C 17.61%, #0C81EE 47.78%, #F9185B 78.77%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  background-clip: text;
}

.btn.has-underline,
.btn.has-underline:hover,
.btn.has-underline:focus {
  border: none;
  border-bottom: 1.5px solid #BBD0E5;
  border-radius: 0;
}

.has-arrow > svg {
  transition: transform 0.15s ease-in-out;
}

.btn-link.has-arrow:focus > svg path {
  transition: fill 0.15s ease-in-out;
}

.btn.has-arrow:focus > svg,
.btn.has-arrow:hover > svg {
  transform: translateX(4px);
}

.btn-link.has-arrow:hover > svg path {
  fill: #3F4759;
}

.btn-link.has-arrow:focus > svg path {
  fill: #949DB0;
}

.hover-scale-fix {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.hover-scale > * {
  overflow: hidden;
  transition: transform 0.15s ease-in-out;
}

.hover-scale:hover > *,
.hover-scale:focus > * {
  transform: scale(1.02);
}

.widest {
  max-width: 840px;
  margin: 0 auto;
}

@media (min-width: 1400px) {
  .widest {
    max-width: 1100px;
  }
}
.tiny {
  max-width: 640px;
  margin: 0 auto;
}

.font-300 {
  font-weight: 300;
}

.font-400 {
  font-weight: 400;
}

.font-500 {
  font-weight: 500;
}

.font-600 {
  font-weight: 600;
}

.font-700 {
  font-weight: 700;
}

.font-800 {
  font-weight: 700;
}

.mt-12 {
  margin-top: 0.75rem;
}

.mb-12 {
  margin-bottom: 0.75rem;
}

.ms-12 {
  margin-left: 0.75rem;
}

.me-12 {
  margin-right: 0.75rem;
}

.pt-12 {
  padding-top: 0.75rem;
}

.pb-12 {
  padding-bottom: 0.75rem;
}

.ps-12 {
  padding-left: 0.75rem;
}

.pe-12 {
  padding-right: 0.75rem;
}

.mt-100 {
  margin-top: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ms-100 {
  margin-left: 100px;
}

.me-100 {
  margin-right: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

@media (min-width: 1024px) {
  .pt-lg-100 {
    padding-top: 100px !important;
  }

  .pb-lg-100 {
    padding-bottom: 100px !important;
  }

  .mt-lg-100 {
    margin-top: 100px !important;
  }

  .mb-lg-100 {
    margin-bottom: 100px !important;
  }

  .mt-lg-150 {
    margin-top: 150px !important;
  }

  .mb-lg-150 {
    margin-bottom: 150px !important;
  }

  .br-lg-16 {
    border-radius: 1rem;
  }

  .br-lg-24 {
    border-radius: 1.5rem;
  }
}
@media (min-width: 1600px) {
  .pt-xl-100 {
    padding-top: 100px !important;
  }

  .pb-xl-100 {
    padding-bottom: 100px !important;
  }

  .mt-xl-200 {
    margin-top: 200px !important;
  }

  .mb-xl-200 {
    margin-bottom: 200px !important;
  }

  .mt-xl-100 {
    margin-top: 100px !important;
  }

  .mb-xl-100 {
    margin-bottom: 100px !important;
  }
}
.text-blue {
  color: #2693FF;
}

.text-green {
  color: #6CC395;
}

.text-red {
  color: #F45F50;
}

.text-orange {
  color: #FCBC41;
}

.text-yellow {
  color: #F2E150;
}

.text-purple {
  color: #9062F0;
}

.text-dark-1 {
  color: #293745;
}

.text-dark-2 {
  color: #2B3140;
}

.text-dark-3 {
  color: #3F4759;
}

.text-dark-4 {
  color: #586276;
}

.text-dark-5 {
  color: #798297;
}

.text-dark-6 {
  color: #949DB0;
}

.text-base-1 {
  color: #7F859D;
}

.text-base-2 {
  color: #A0A5B8;
}

.text-base-3 {
  color: #BBD0E5;
}

.text-base-4 {
  color: #DAE4EA;
}

.text-base-5 {
  color: #E7EFF3;
}

.text-base-6 {
  color: #EFF6FC;
}

.text-mid-1 {
  color: #97989A;
}

.text-mid-2 {
  color: #ABABAB;
}

.text-mid-3 {
  color: #DADADA;
}

.text-mid-4 {
  color: #E4E4E4;
}

.text-mid-5 {
  color: #F3F3F3;
}

.text-mid-6 {
  color: #FDFDFD;
}

.text-sky-bridge {
  color: #3CACD7;
}

.text-sky-clean {
  color: #50C1EC;
}

.text-black-panther {
  color: #293745;
}

.text-the-dcotor {
  color: #F8FAFB;
}

.btn-primary {
  color: #fff;
}

.btn-primary:hover,
.btn-primary:focus {
  color: #fff;
  background-color: #50C1EC;
}

.btn-secondary {
  color: #3F4759;
}

.btn-secondary:hover {
  background-color: #fff;
  color: #798297;
  border-color: #DAE4EA;
}

.btn-secondary:focus,
.btn-secondary:active {
  background-color: #DAE4EA;
  color: #3F4759;
}

.btn-link {
  padding: 0;
}

.btn-link:hover {
  color: #3F4759;
}

.btn-link:focus,
.btn-link:active {
  color: #949DB0;
}

.btn-link:disabled {
  color: #97989A;
}

.btn-chevron,
.btn-chevron:hover,
.btn-chevron:focus {
  width: 48px;
  height: 48px;
  border-radius: 6px;
  padding: 0;
  background-color: var(--visme-black-panther);
  border-color: var(--visme-black-panther);
}

.btn-chevron svg path {
  transition: fill 0.15s ease-in-out;
}

.btn-chevron:disabled {
  background: #DBE1E7;
  border-color: #DBE1E7;
}

.btn-chevron svg path {
  fill: var(--visme-the-dcotor);
}

.btn-chevron:disabled svg path {
  fill: #a0a5b8;
}

/* ------------ Cookies popup ------------ */
.cookies-popup {
  position: fixed;
  bottom: 0;
  background-color: #fafbfd;
  z-index: 1;
  width: 100%;
  box-shadow: 0 0 12px 3px rgba(184, 184, 184, 0.3);
  padding: 8px 0;
  display: none;
  z-index: 4;
}

.cookies-popup p {
  font-size: 12px !important;
  padding-right: 30px;
  margin: 0;
  line-height: 21px;
  color: #000;
  text-align: left;
  font-family: lato, sans-serif !important;
  margin-bottom: 0 !important;
}

.cookies-popup .container {
  display: flex;
  align-items: center;
}

.cookies-popup .accept-cookies {
  background-color: #293042;
  color: #fff;
  border: 0;
  padding: 5px 17px;
  border-radius: 8px;
  font-size: 14px;
}

/*# sourceMappingURL=bootstrap.css.map */