// stylelint-disable custom-property-empty-line-before

:root {
  // Note: Custom variable values only support SassScript inside `#{}`.

  // Colors
  //
  // Generate palettes for full colors, grays, and theme colors.

  @each $color, $value in $colors {
    --#{$prefix}#{$color}: #{$value};
  }

  @each $color, $value in $grays {
    --#{$prefix}gray-#{$color}: #{$value};
  }

  @each $color, $value in $theme-colors {
    --#{$prefix}#{$color}: #{$value};
  }

$sky-bridge: #3CACD7 !default;
$sky-clean: #50C1EC !default;
$black-panther: #293745 !default;
$the-dcotor: #F8FAFB !default;

$dark-1: $black-panther;
$dark-2: #2B3140;
$dark-3: #3F4759;
$dark-4: #586276;
$dark-5: #798297;
$dark-6: #949DB0;

$mid-1: #97989A;
$mid-2: #ABABAB;
$mid-3: #DADADA;
$mid-4: #E4E4E4;
$mid-5: #F3F3F3;
$mid-6: #FDFDFD;

$base-1: #7F859D;
$base-2: #A0A5B8;
$base-3: #BBD0E5;
$base-4: #DAE4EA;
$base-5: #E7EFF3;
$base-6: #EFF6FC;

$warning-1: #CF9627;
$warning-2: #E4A833;
$warning-4: #FECF73;
$warning-5: #FFE5B1;

$danger-1: #CA4438;
$danger-2: #E15144;
$danger-4: #FF7669;
$danger-5: #FFAEA7;

$success-1: #48906B;
$success-2: #5EAE84;
$success-4: #98DCB8;
$success-5: #CAF5DF;

  --#{$prefix}body: #{$body-color};
  --#{$prefix}sky-bridge: #{$sky-bridge};
  --#{$prefix}sky-clean: #{$sky-clean};
  --#{$prefix}black-panther: #{$black-panther};
  --#{$prefix}the-dcotor: #{$the-dcotor};
  --#{$prefix}dark-1: #{$dark-1};
  --#{$prefix}dark-2: #{$dark-2};
  --#{$prefix}dark-3: #{$dark-3};
  --#{$prefix}dark-4: #{$dark-4};
  --#{$prefix}dark-5: #{$dark-5};
  --#{$prefix}dark-6: #{$dark-6};
  --#{$prefix}base-1: #{$base-1};
  --#{$prefix}base-2: #{$base-2};
  --#{$prefix}base-3: #{$base-3};
  --#{$prefix}base-4: #{$base-4};
  --#{$prefix}base-5: #{$base-5};
  --#{$prefix}base-6: #{$base-6};
  --#{$prefix}mid-1: #{$mid-1};
  --#{$prefix}mid-2: #{$mid-2};
  --#{$prefix}mid-3: #{$mid-3};
  --#{$prefix}mid-4: #{$mid-4};
  --#{$prefix}mid-5: #{$mid-5};
  --#{$prefix}mid-6: #{$mid-6};
  --#{$prefix}warning-1: #{$warning-1};
  --#{$prefix}warning-2: #{$warning-2};
  --#{$prefix}warning-4: #{$warning-4};
  --#{$prefix}warning-5: #{$warning-5};
  --#{$prefix}success-1: #{$success-1};
  --#{$prefix}success-2: #{$success-2};
  --#{$prefix}success-4: #{$success-4};
  --#{$prefix}success-5: #{$success-5};
  --#{$prefix}danger-1: #{$danger-1};
  --#{$prefix}danger-2: #{$danger-2};
  --#{$prefix}danger-4: #{$danger-4};
  --#{$prefix}danger-5: #{$danger-5};

  @each $color, $value in $theme-colors-rgb {
    --#{$prefix}#{$color}-rgb: #{$value};
  }

  --#{$prefix}white-rgb: #{to-rgb($white)};
  --#{$prefix}black-rgb: #{to-rgb($black)};
  --#{$prefix}body-color-rgb: #{to-rgb($body-color)};
  --#{$prefix}body-bg-rgb: #{to-rgb($body-bg)};

  // Fonts

  // Note: Use `inspect` for lists so that quoted items keep the quotes.
  // See https://github.com/sass/sass/issues/2383#issuecomment-336349172
  --#{$prefix}font-sans-serif: #{inspect($font-family-sans-serif)};
  --#{$prefix}font-monospace: #{inspect($font-family-monospace)};
  --#{$prefix}gradient: #{$gradient};

  // Root and body
  // scss-docs-start root-body-variables
  @if $font-size-root != null {
    --#{$prefix}root-font-size: #{$font-size-root};
  }
  --#{$prefix}body-font-family: #{$font-family-base};
  @include rfs($font-size-base, --#{$prefix}body-font-size);
  --#{$prefix}body-font-weight: #{$font-weight-base};
  --#{$prefix}body-line-height: #{$line-height-base};
  --#{$prefix}body-color: #{$body-color};
  @if $body-text-align != null {
    --#{$prefix}body-text-align: #{$body-text-align};
  }
  --#{$prefix}body-bg: #{$body-bg};
  // scss-docs-end root-body-variables

  // scss-docs-start root-border-var
  --#{$prefix}border-width: #{$border-width};
  --#{$prefix}border-style: #{$border-style};
  --#{$prefix}border-color: #{$border-color};
  --#{$prefix}border-color-translucent: #{$border-color-translucent};

  --#{$prefix}border-radius: #{$border-radius};
  --#{$prefix}border-radius-sm: #{$border-radius-sm};
  --#{$prefix}border-radius-lg: #{$border-radius-lg};
  --#{$prefix}border-radius-xl: #{$border-radius-xl};
  --#{$prefix}border-radius-2xl: #{$border-radius-2xl};
  --#{$prefix}border-radius-pill: #{$border-radius-pill};
  // scss-docs-end root-border-var

  --#{$prefix}heading-color: #{$headings-color};
  --#{$prefix}link-color: #{$link-color};
  --#{$prefix}link-hover-color: #{$link-hover-color};

  --#{$prefix}code-color: #{$code-color};

  --#{$prefix}highlight-bg: #{$mark-bg};
}
