.lead {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.7;
}

.large {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45; 
}

@media (min-width: 1200px) {
    .lead {
        font-size: 1.25rem;
    }
    .large {
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.45; 
    }
}

.list-unstyled {
    list-style: none;
}

.user-select-none {
    user-select: none;
    -webkit-user-select: none;
}

.box-shadow {
    box-shadow: 0px 56px 80px rgba(0, 0, 0, 0.04);
}

.br-6 {
    border-radius: 0.375rem;
}

.br-8 {
    border-radius: 0.5rem;
}

.br-12 {
    border-radius: 0.75rem;
}

.br-16 {
    border-radius: 1rem;
}

.br-24 {
    border-radius: 1.5rem;
}

.br-50 {
    border-radius: 50%;
}
  
.small {
    @extend small;
}

.object-cover {
    object-fit: cover;
}

.has-gradient {
    background: linear-gradient(90.09deg, #1A2A6C 17.61%, #0C81EE 47.78%, #F9185B 78.77%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
    background-clip: text;
}

.btn.has-underline,
.btn.has-underline:hover,
.btn.has-underline:focus {
    border: none;
    border-bottom: 1.5px solid $base-3;
    border-radius: 0;
}

.has-arrow > svg {
    transition: transform 0.15s ease-in-out;
}

.btn-link.has-arrow:focus > svg path {
    transition: fill 0.15s ease-in-out;
}

.btn.has-arrow:focus > svg,
.btn.has-arrow:hover > svg {
    transform: translateX(4px);
}

.btn-link.has-arrow:hover > svg path {
    fill: $dark-3;
}

.btn-link.has-arrow:focus > svg path {
    fill: $dark-6;
}

.hover-scale-fix {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.hover-scale > * {
    overflow: hidden;
    transition: transform 0.15s ease-in-out;
}

.hover-scale:hover > *,
.hover-scale:focus > * {
    transform: scale(1.02);
}

.widest {
    max-width: 840px;
    margin: 0 auto;
}

@media (min-width: 1400px) {
    .widest {
        max-width: 1100px;
    }
}

.tiny {
    max-width: 640px;
    margin: 0 auto;
}

.font-300 {
    font-weight: 300;
}

.font-400 {
    font-weight: 400;
}

.font-500 {
    font-weight: 500;
}

.font-600 {
    font-weight: 600;
}

.font-700 {
    font-weight: 700;
}

.font-800 {
    font-weight: 700;
}

.mt-12 {
    margin-top: 0.75rem;
}

.mb-12 {
    margin-bottom: 0.75rem;
}

.ms-12 {
    margin-left: 0.75rem;
}

.me-12 {
    margin-right: 0.75rem;
}

.pt-12 {
    padding-top: 0.75rem;
}

.pb-12 {
    padding-bottom: 0.75rem;
}

.ps-12 {
    padding-left: 0.75rem;
}

.pe-12 {
    padding-right: 0.75rem;
}

.mt-100 {
    margin-top: 100px;
}

.mb-100 {
    margin-bottom: 100px;
}

.ms-100 {
    margin-left: 100px;
}

.me-100 {
    margin-right: 100px;
}

.pt-100 {
    padding-top: 100px;
}

.pb-100 {
    padding-bottom: 100px;
}

@media (min-width: 1024px) {
    .pt-lg-100 {
        padding-top: 100px !important;
    }
    
    .pb-lg-100 {
        padding-bottom: 100px !important;
    }

    .mt-lg-100 {
        margin-top: 100px !important;
    }
    
    .mb-lg-100 {
        margin-bottom: 100px !important;
    }

    .mt-lg-150 {
        margin-top: 150px !important;
    }
    
    .mb-lg-150 {
        margin-bottom: 150px !important;
    }

    .br-lg-16 {
        border-radius: 1rem;
    }

    .br-lg-24 {
        border-radius: 1.5rem;
    }
}

@media (min-width: 1600px) {
    .pt-xl-100 {
        padding-top: 100px !important;
    }
    
    .pb-xl-100 {
        padding-bottom: 100px !important;
    }

    .mt-xl-200 {
        margin-top: 200px !important;
    }
    
    .mb-xl-200 {
        margin-bottom: 200px !important;
    }

    .mt-xl-100 {
        margin-top: 100px !important;
    }
    
    .mb-xl-100 {
        margin-bottom: 100px !important;
    }
}

.text-blue {
    color: $blue;
}

.text-green {
    color: $green;
}

.text-red {
    color: $red;
}

.text-orange {
    color: $orange;
}

.text-yellow {
    color: $yellow;
}

.text-purple {
    color: $purple;
}

.text-dark-1 {
    color: $dark-1;
}

.text-dark-2 {
    color: $dark-2;
}

.text-dark-3 {
    color: $dark-3;
}

.text-dark-4 {
    color: $dark-4;
}

.text-dark-5 {
    color: $dark-5;
}

.text-dark-6 {
    color: $dark-6;
}

.text-base-1 {
    color: $base-1;
}

.text-base-2 {
    color: $base-2;
}

.text-base-3 {
    color: $base-3;
}

.text-base-4 {
    color: $base-4;
}

.text-base-5 {
    color: $base-5;
}

.text-base-6 {
    color: $base-6;
}

.text-mid-1 {
    color: $mid-1;
}

.text-mid-2 {
    color: $mid-2;
}

.text-mid-3 {
    color: $mid-3;
}

.text-mid-4 {
    color: $mid-4;
}

.text-mid-5 {
    color: $mid-5;
}

.text-mid-6 {
    color: $mid-6;
}

.text-sky-bridge {
    color: $sky-bridge;
}

.text-sky-clean {
    color: $sky-clean;
}

.text-black-panther {
    color: $black-panther;
}

.text-the-dcotor {
    color: $the-dcotor;
}

.btn-primary {
    color: #fff;
}

.btn-primary:hover,
.btn-primary:focus {
    color: #fff;
    background-color: $sky-clean;
}

.btn-secondary {
    color: $dark-3;
}

.btn-secondary:hover {
    background-color: $white;
    color: $dark-5;
    border-color: $base-4;
}

.btn-secondary:focus,
.btn-secondary:active {
    background-color: $base-4;
    color: $dark-3;
}

.btn-link {
    padding: 0;
}

.btn-link:hover {
    color: $dark-3;
}

.btn-link:focus,
.btn-link:active {
    color: $dark-6;
}

.btn-link:disabled {
    color: $mid-1;
}

.btn-chevron,
.btn-chevron:hover,
.btn-chevron:focus {
    width: 48px;
    height: 48px;
    border-radius: 6px;
    padding: 0;
    background-color: var(--visme-black-panther);
    border-color: var(--visme-black-panther);
}
.btn-chevron svg path {
    transition: fill 0.15s ease-in-out;
}
.btn-chevron:disabled {
    background: #DBE1E7;
    border-color: #DBE1E7;
}
.btn-chevron svg path {
    fill: var(--visme-the-dcotor);
}

.btn-chevron:disabled svg path {
    fill: #a0a5b8;
}

/* ------------ Cookies popup ------------ */
.cookies-popup {
    position: fixed;
    bottom: 0;
    background-color: #fafbfd;
    z-index: 1;
    width: 100%;
    box-shadow: 0 0 12px 3px rgba(184, 184, 184, 0.3);
    padding: 8px 0;
    display: none;
    z-index: 4;
}

.cookies-popup p {
    font-size: 12px !important;
    padding-right: 30px;
    margin: 0;
    line-height: 21px;
    color: #000;
    text-align: left;
    font-family: lato, sans-serif !important;
    margin-bottom: 0 !important;
}

.cookies-popup .container {
    display: flex;
    align-items: center;
}

.cookies-popup .accept-cookies {
    background-color: #293042;
    color: #fff;
    border: 0;
    padding: 5px 17px;
    border-radius: 8px;
    font-size: 14px;
}